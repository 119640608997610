import Keycloak from 'keycloak-js'

import { getEnv } from './config'

const keycloakUrl = getEnv('SPA_KEYCLOAK_SERVER_URL')
const authResourceEnv = getEnv('SPA_AUTH_RESOURCE_ENV')
const clientId = 'field-management-public'
const realm = getEnv('SPA_KEYCLOAK_REALM')
const keycloakAdmin = 'admin'

export const keycloakAppResourceName = 'gmini_checklist_management'
export const keycloakAdminRole = getRole(keycloakAdmin, authResourceEnv)

function getRole(role: string, envType: string): string {
  return `${role}_${envType}`
}

export const keycloakClient = Keycloak({
  clientId,
  realm,
  url: keycloakUrl,
})
